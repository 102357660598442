import { useLayoutEffect, useState } from 'react';
import { useOutletContext, useParams, useSearchParams } from 'react-router-dom';

import { Button, Group, Skeleton, Text, Title } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { IconCircleCheck, IconFileTypePdf } from '@tabler/icons-react';
import { dateToDateString } from '@utils/date';
import { priceToDecimal } from '@utils/price';

import env from '@config/env';

import {
  generateGetOffersProductsMock,
  generateGetSingleOfferMock,
} from '@api/mocks/offers.mock';
import {
  GetOfferProductQueryParams,
  OffersProductsSortBy,
  useConfirmOfferMutation,
  useGetOfferProductsCountQuery,
  useGetOfferProductsQuery,
  useGetSingleOfferQuery,
} from '@api/offers.api';

import Card from '@components/card/Card';
import { LayoutContextType } from '@components/layout/Layout';
import PaginationRow from '@components/PaginationRow';
import SearchBar from '@components/SearchBar';
import SortableTable from '@components/sortableTable/SortableTable';

import Forbidden from './Forbidden';
import NotFound from './NotFound';

export default function OfferDetail() {
  // ==========================================================================
  // General
  // ==========================================================================
  const { id } = useParams();

  const [searchParams] = useSearchParams();

  // ==========================================================================
  // State
  // ==========================================================================
  const [filters, setFilters] = useState<GetOfferProductQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 5),
    searchQuery: searchParams.get('search') || undefined,
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });

  // ==========================================================================
  // Api
  // ==========================================================================
  const {
    data: offer = generateGetSingleOfferMock(),
    isLoading,
    error,
  } = useGetSingleOfferQuery(id!);

  const [confirmOffer, { isLoading: isLoadingConfirmOffer }] =
    useConfirmOfferMutation();

  // Get initial products count
  const {
    data: productsCount = { count: 0 },
    isLoading: isLoadingProductsCount,
  } = useGetOfferProductsCountQuery({ id: id!, searchQuery });

  const {
    data: productsData = generateGetOffersProductsMock(),
    isLoading: isProductsLoading,
  } = useGetOfferProductsQuery({
    id: id!,
    params: { ...filters, searchQuery },
  });

  // Map data
  const products = productsData.map((product) => {
    const data = [
      <img
        src={product.imageUrl}
        alt={product.name}
        style={{ height: '2rem' }}
      />,
      product.sku,
      product.name,
      product.color,
      product.size,
      product.quantity,

      <Group gap={3} wrap="nowrap">
        <span>€</span>
        {product.salePercent && (
          <span>
            <span style={{ textDecoration: 'line-through' }}>
              {priceToDecimal(product.totalPrice)}
            </span>
          </span>
        )}
        <span>
          {priceToDecimal(
            product.totalPrice * (1 - (product.salePercent || 0) / 1000),
          )}
        </span>
      </Group>,
      product.salePercent ? `${product.salePercent / 10}%` : '-',
    ];

    return {
      key: product.id,
      data,
    };
  });

  // ==========================================================================
  // Handler
  // ==========================================================================
  const handleConfirmClick = async () => {
    try {
      await confirmOffer({ id: id! }).unwrap();
    } catch (e) {
      console.error(e);

      showNotification({
        title: "Errore nella conferma dell'offerta!",
        message: "Impossibile confermare l'offerta.",
        color: 'red',
      });
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const { setLayoutProps } = useOutletContext<LayoutContextType>();

  useLayoutEffect(() => {
    setLayoutProps({
      title: `Dettagli offerta #${id}`,
      backLink: { title: 'Lista offerte', to: '/offerte' },
    });
  }, [id, setLayoutProps]);

  const totalPages = Math.ceil(productsCount.count / filters.pageLength!);

  return !isLoading &&
    (!offer || (error && (error as FetchBaseQueryError).status === 404)) ? (
    <NotFound />
  ) : !isLoading &&
    (!offer || (error && (error as FetchBaseQueryError).status === 403)) ? (
    <Forbidden />
  ) : (
    <>
      <Group grow align="start">
        <Card
          title="Informazioni offerta"
          action={{
            title: 'Scarica pdf',
            icon: <IconFileTypePdf />,
            onClick: () => {
              window.open(
                `${env.RESOURCES_URL}/offerte/${offer.pdfName}`,
                '_blank',
              );
            },
          }}
        >
          <Group justify="space-between">
            <Text fw="bold">Numero offerta</Text>
            <Skeleton visible={isLoading}>{offer.id}</Skeleton>
          </Group>
          <Group justify="space-between">
            <Text fw="bold">Data</Text>
            <Skeleton visible={isLoading}>
              {dateToDateString(new Date(offer.date))}
            </Skeleton>
          </Group>

          {offer.confirmationDate ? (
            <Text mt="sm">
              <strong>Offerta confermata</strong>
            </Text>
          ) : (
            <Button
              leftSection={<IconCircleCheck />}
              mt="lg"
              onClick={handleConfirmClick}
              loading={isLoadingConfirmOffer}
            >
              Conferma offerta
            </Button>
          )}
        </Card>

        <Card title="Pagamento">
          <Group justify="space-between">
            <Text fw="bold">Tipo di pagamento</Text>
            <Skeleton visible={isLoading}>
              {offer.paymentType === 'onlineOnConfirm'
                ? 'Pagamento online'
                : offer.paymentType === 'bankTransferOnConfirm'
                  ? 'Bonifico bancario'
                  : 'Bonifico bancario'}
            </Skeleton>
          </Group>
          <Group justify="space-between">
            <Text fw="bold">Totale (Iva In.)</Text>
            <Skeleton visible={isLoading}>
              € {priceToDecimal(offer.totalPrice)}
            </Skeleton>
          </Group>
        </Card>
      </Group>

      <Title order={3} mt="lg">
        Prodotti
      </Title>
      <Group mt="lg" mb="md">
        <SearchBar
          placeholder="Ricerca per codice o nome prodotto"
          value={filters.searchQuery}
          onChange={(newValue) =>
            setFilters({ ...filters, searchQuery: newValue })
          }
        />
      </Group>

      <SortableTable
        data={products}
        headings={{
          imageUrl: '',
          sku: 'Codice',
          name: 'Nome',
          color: 'Colore',
          size: 'Taglia',
          quantity: 'Quantità',
          totalPrice: 'Prezzo totale',
          salePercent: 'Sconto',
        }}
        sortableKeys={['sku', 'name']}
        onSortingChange={(key, order) =>
          setFilters({
            ...filters,
            sortBy: key as OffersProductsSortBy,
            sortOrder: order,
          })
        }
        emptyText="Nessun prodotto trovato"
        loading={isProductsLoading || isLoadingProductsCount}
      />
      <PaginationRow
        page={filters.page!}
        pageLength={filters.pageLength!}
        totalPages={totalPages}
        onPageChange={(newPage) => setFilters({ ...filters, page: newPage })}
        onPageLengthChange={(newPageLength) =>
          setFilters({ ...filters, pageLength: newPageLength, page: 1 })
        }
      />
    </>
  );
}
