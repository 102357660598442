import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import {
  Anchor,
  Button,
  Checkbox,
  Divider,
  Group,
  PasswordInput,
  Select,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { handleSubmitError } from '@utils/forms';

import { SignupRequestBody } from '@interfaces/auth.interface';
import { ClientType } from '@interfaces/clientsProfiles.interface';

import { useGetCitiesQuery, useGetProvincesQuery } from '@api/locations.api';

import useAuth from '@hooks/useAuth';
import useGRecaptcha from '@hooks/useGRecaptcha';

import FormsPaper from '@components/FormsPaper';

import classes from './Register.module.css';

export default function Register() {
  // ==========================================================================
  // General
  // ==========================================================================
  const { isAuthenticated, signup, isLoading } = useAuth();

  const navigate = useNavigate();

  const { getRecaptchaToken } = useGRecaptcha();

  const [searchParams] = useSearchParams();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    email: '',
    emailConfirm: '',
    password: '',
    passwordConfirm: '',
    name: '',
    surname: '',
    companyName: '',
    vatTaxNumber: '',
    phone: '',
    pec: '',
    sdi: '',
    address: '',
    city: '',
    zip: '',
    province: '',
    type: 'private',
    sellerCode: searchParams.get('sc') || '',
  };

  const form = useForm({
    initialValues,
    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Email non valida'),
      emailConfirm: (value, values) =>
        value !== values.email ? 'Le email non corrispondono.' : null,
      passwordConfirm: (value, values) =>
        value !== values.password ? 'Le password non corrispondono.' : null,
    },
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      const data: SignupRequestBody = {
        email: values.email,
        password: values.password,
        name:
          values.type === 'private'
            ? `${values.name} ${values.surname}`
            : values.companyName,
        vatTaxNumber: values.vatTaxNumber,
        phone: values.phone,
        address: values.address,
        city: values.city,
        zip: values.zip,
        province: values.province,
        type: values.type as ClientType,
        sellerCode: values.sellerCode,
      };

      if (data.type === 'company') {
        data.pec = values.pec;
        data.sdi = values.sdi;
      }

      const recaptchaToken = await getRecaptchaToken('SignUp');

      await signup({ body: data, recaptchaToken });

      showNotification({
        title: 'Benvenuto nel team!',
        message:
          'La registrazione è andata a buon fine. Controlla la tua casella email per confermare il tuo account.',
        icon: <IconCheck />,
        color: 'teal',
      });

      navigate('/');
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: provinces = [], isLoading: isLoadingProvinces } =
    useGetProvincesQuery();

  const { data: cities = [], isLoading: isLoadingCities } = useGetCitiesQuery(
    form.values.province,
    { skip: form.values.province === '' },
  );

  // ==========================================================================
  // Render
  // ==========================================================================
  return isAuthenticated ? (
    <Navigate to="/ordini" replace={true} />
  ) : (
    <>
      <Text ta="center">
        Hai già un account?{' '}
        <Anchor to="/" component={Link}>
          Accedi
        </Anchor>
      </Text>

      <FormsPaper mt={30}>
        <form
          onSubmit={form.onSubmit((values) => {
            onSubmit(values);
          })}
        >
          <Group wrap="nowrap" className={classes.inputGroup}>
            <TextInput
              className={classes.inputField}
              label="Email"
              placeholder="email@example.com"
              required
              {...form.getInputProps('email')}
            />
            <TextInput
              className={classes.inputField}
              label="Ripeti email"
              placeholder="email@example.com"
              required
              {...form.getInputProps('emailConfirm')}
            />
          </Group>
          <Group wrap="nowrap" mt="md" className={classes.inputGroup}>
            <PasswordInput
              className={classes.inputField}
              label="Password"
              placeholder="La tua password"
              required
              {...form.getInputProps('password')}
            />
            <PasswordInput
              className={classes.inputField}
              label="Ripeti password"
              placeholder="La tua password"
              required
              {...form.getInputProps('passwordConfirm')}
            />
          </Group>
          <Divider my="md" />
          <Group mt="md">
            <Checkbox
              label="Sono un azienda"
              checked={form.values.type === 'company'}
              onChange={(e) => {
                if (e.currentTarget.checked) {
                  form.setFieldValue('type', 'company');
                } else {
                  form.setFieldValue('type', 'private');
                }
              }}
            />
          </Group>
          {form.values.type === 'company' && (
            <>
              <Group wrap="nowrap" mt="md" className={classes.inputGroup}>
                <TextInput
                  className={classes.inputField}
                  label="Ragione sociale"
                  placeholder="Azienda s.r.l."
                  required
                  {...form.getInputProps('companyName')}
                />
                <TextInput
                  className={classes.inputField}
                  label="P. Iva o Codice fiscale"
                  placeholder="PIVA o CF"
                  required
                  {...form.getInputProps('vatTaxNumber')}
                />
              </Group>
              <Group wrap="nowrap" mt="md" className={classes.inputGroup}>
                <TextInput
                  className={classes.inputField}
                  label="SDI"
                  placeholder="SDI dell'azienda"
                  required
                  {...form.getInputProps('sdi')}
                />
                <TextInput
                  className={classes.inputField}
                  label="Pec"
                  placeholder="aziendasrl@pec.it"
                  required
                  {...form.getInputProps('pec')}
                />
              </Group>
            </>
          )}
          {form.values.type === 'private' && (
            <>
              <Group wrap="nowrap" mt="md" className={classes.inputGroup}>
                <TextInput
                  className={classes.inputField}
                  label="Nome"
                  placeholder="Il tuo nome"
                  required
                  {...form.getInputProps('name')}
                />
                <TextInput
                  className={classes.inputField}
                  label="Cognome"
                  placeholder="Il tuo cognome"
                  required
                  {...form.getInputProps('surname')}
                />
              </Group>
              <Group wrap="nowrap" mt="md" className={classes.inputGroup}>
                <TextInput
                  className={classes.inputField}
                  label="Codice fiscale"
                  placeholder="Il tuo codice fiscale"
                  required
                  {...form.getInputProps('vatTaxNumber')}
                />
              </Group>
            </>
          )}
          <Group wrap="nowrap" mt="md" className={classes.inputGroup}>
            <Select
              className={classes.inputField}
              label="Provincia"
              placeholder="Seleziona provincia"
              searchable
              nothingFoundMessage="Nessun risultato"
              required
              disabled={isLoadingProvinces}
              data={provinces.map((province) => ({
                value: province.sigla,
                label: province.sigla,
              }))}
              {...form.getInputProps('province')}
              onChange={(e) => {
                form.setFieldValue('city', '');
                form.setFieldValue('zip', '');
                form.getInputProps('province').onChange(e);
              }}
            />
          </Group>
          <Group wrap="nowrap" mt="md" className={classes.inputGroup}>
            <Select
              className={classes.inputField}
              label="Città"
              placeholder="Seleziona città"
              searchable
              nothingFoundMessage="Nessun risultato"
              required
              disabled={form.values.province === '' || isLoadingCities}
              data={cities.map((city) => ({
                value: city.nome,
                label: city.nome,
              }))}
              {...form.getInputProps('city')}
              onChange={(e) => {
                form.setFieldValue('zip', '');
                form.getInputProps('city').onChange(e);
              }}
            />
            <Select
              className={classes.inputField}
              label="CAP"
              placeholder="Seleziona CAP"
              searchable
              nothingFoundMessage="Nessun risultato"
              required
              disabled={form.values.city === '' || isLoadingCities}
              data={[
                {
                  value:
                    cities.find((c) => c.nome === form.values.city)?.cap || '',
                  label:
                    cities.find((c) => c.nome === form.values.city)?.cap || '',
                },
              ]}
              {...form.getInputProps('zip')}
            />
          </Group>
          <Group wrap="nowrap" mt="md" className={classes.inputGroup}>
            <TextInput
              className={classes.inputField}
              label="Indirizzo"
              placeholder="Via Principale, 20"
              required
              {...form.getInputProps('address')}
            />
            <TextInput
              className={classes.inputField}
              label="Telefono"
              placeholder="345 1213456"
              required
              {...form.getInputProps('phone')}
            />
          </Group>

          <Divider my="md" />

          <Text size="sm" mb="xs">
            Sei affiliato ad una società sportiva?
          </Text>
          <TextInput
            className={classes.inputField}
            label="Inserisci il codice società (facoltativo)"
            placeholder="Codice società"
            description="Il codice società non è necessario per completare la registrazione. Potrà essere inserito in un secondo momento."
            {...form.getInputProps('sellerCode')}
          />

          {form.errors.general && (
            <Text c="red" size="sm" mt="xl">
              {form.errors.general}
            </Text>
          )}
          <Button
            type="submit"
            mx="auto"
            mt="xl"
            style={{ display: 'block' }}
            loading={isLoading}
          >
            Registrati
          </Button>
        </form>
      </FormsPaper>
    </>
  );
}
