import { ClientType } from '@interfaces/clientsProfiles.interface';
import { NotificationCategory } from '@interfaces/notifications.interface';
import {
  OrderProductStatus,
  OrderSource,
  OrderStatus,
  PaymentType,
  ShippingMethod,
} from '@interfaces/orders.interface';

type MockableCustomTypes =
  | 'ShippingMethod'
  | 'OrderStatus'
  | 'OrderProductStatus'
  | 'PaymentType'
  | 'OrderSource'
  | 'ClientType'
  | 'NotificationCategory';

type MockableType =
  | 'string'
  | 'number'
  | 'boolean'
  | 'date'
  | 'null'
  | MockableCustomTypes;

interface MockableStringOptions {
  type: 'string';
  minLength?: number;
  maxLength?: number;
}

interface MockableNumberOptions {
  type: 'number';
  min?: number;
  max?: number;
  decimals?: number;
}

interface MockableDateOptions {
  type: 'date';
  minDate?: Date;
  maxDate?: Date;
}

interface MockableArrayOptions {
  type: 'array';
  minLength?: number;
  maxLength?: number;
  each: MockTemplate;
}

interface MockableObjectOptions {
  type: 'object';
  values: Record<string, MockTemplate>;
}

export type MockTemplate =
  | MockableType
  | MockableStringOptions
  | MockableNumberOptions
  | MockableDateOptions
  | MockableArrayOptions
  | MockableObjectOptions;

const generateRandomString = (minLength: number, maxLength: number): string => {
  const length =
    Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return result;
};

const generateRandomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const generateRandomDate = (minDate: Date, maxDate: Date): Date => {
  return new Date(
    minDate.getTime() + Math.random() * (maxDate.getTime() - minDate.getTime()),
  );
};

const generateRandomArray = (
  minLength: number,
  maxLength: number,
  type: MockTemplate,
): unknown[] => {
  const length = generateRandomNumber(minLength, maxLength);

  return new Array(length).fill(null).map(() => generateMockValue(type));
};

const generateRandomCustomType = (type: MockableCustomTypes) => {
  const shippingMethods: ShippingMethod[] = [
    'courier',
    'handPickupWarehouse',
    'handPickupShop',
    'handPickupShopBassano',
    'handPickupShopSpes',
  ];
  const orderStatuses: OrderStatus[] = [
    'inserted',
    'waitingConfirmation',
    'waitingPayment',
    'processingPayment',
    'confirmed',
    'production',
    'closed',
  ];
  const orderProductStatuses: OrderProductStatus[] = [
    'inserted',
    'checkingAvailability',
    'waitingConfirmation',
    'waitingPayment',
    'confirmed',
    'production',
    'waitingGraphicsConfirmation',
    'graphicsConfirmed',
    'readyToShip',
    'closed',
    'cancelled',
  ];
  const paymentTypes: PaymentType[] = [
    'onlineStripe',
    'onlinePaypal',
    'onlineOnConfirm',
    'bankTransferOnConfirm',
    'other',
  ];
  const clientTypes: ClientType[] = ['private', 'company'];
  const orderSources: OrderSource[] = ['web', 'filemaker'];
  const notificationCategories: NotificationCategory[] = [
    'newDocumentDdt',
    'newDocumentInvoice',
    'newDocumentOffer',
    'newGraphicsDraft',
    'newOrder',
    'orderStatusChange',
    'other',
  ];

  switch (type) {
    case 'ShippingMethod':
      return shippingMethods[
        Math.floor(Math.random() * shippingMethods.length)
      ];
    case 'OrderStatus':
      return orderStatuses[Math.floor(Math.random() * orderStatuses.length)];
    case 'OrderProductStatus':
      return orderProductStatuses[
        Math.floor(Math.random() * orderProductStatuses.length)
      ];
    case 'PaymentType':
      return paymentTypes[Math.floor(Math.random() * paymentTypes.length)];
    case 'ClientType':
      return clientTypes[Math.floor(Math.random() * clientTypes.length)];
    case 'OrderSource':
      return orderSources[Math.floor(Math.random() * orderSources.length)];
    case 'NotificationCategory':
      return notificationCategories[
        Math.floor(Math.random() * notificationCategories.length)
      ];
  }
};

// Generate Mock Template Value
// ======================================================

type GenerateMockValue<T extends MockTemplate> = T extends MockableStringOptions
  ? string
  : T extends MockableNumberOptions
    ? number
    : T extends MockableDateOptions
      ? Date
      : T extends MockableArrayOptions
        ? GenerateMockValue<T['each']>[]
        : T extends MockableObjectOptions
          ? { [K in keyof T['values']]: GenerateMockValue<T['values'][K]> }
          : T extends MockableType
            ? T extends 'string'
              ? string
              : T extends 'number'
                ? number
                : T extends 'boolean'
                  ? boolean
                  : T extends 'date'
                    ? Date
                    : T extends 'null'
                      ? null
                      : T extends 'ShippingMethod'
                        ? ShippingMethod
                        : T extends 'OrderStatus'
                          ? OrderStatus
                          : T extends 'OrderProductStatus'
                            ? OrderProductStatus
                            : T extends 'PaymentType'
                              ? PaymentType
                              : T extends 'ClientType'
                                ? ClientType
                                : T extends 'OrderSource'
                                  ? OrderSource
                                  : T extends 'NotificationCategory'
                                    ? NotificationCategory
                                    : never
            : never;

export const generateMockValue = <T extends MockTemplate>(
  type: T,
): GenerateMockValue<T> => {
  const options =
    typeof type === 'string'
      ? { type }
      : (type as Exclude<MockTemplate, MockableType>);

  switch (options.type) {
    case 'string': {
      const o = options as MockableStringOptions;
      return generateRandomString(
        o.minLength || 5,
        o.maxLength || 20,
      ) as GenerateMockValue<T>;
    }
    case 'number': {
      const o = options as MockableNumberOptions;
      return generateRandomNumber(
        o.min || 1,
        o.max || 100000,
      ) as GenerateMockValue<T>;
    }
    case 'boolean':
      return (Math.random() < 0.5) as GenerateMockValue<T>;
    case 'date': {
      const o = options as MockableDateOptions;
      return generateRandomDate(
        o.minDate || new Date(2000, 0, 1),
        o.maxDate || new Date(2023, 11, 1),
      ) as GenerateMockValue<T>;
    }
    case 'null':
      return null as GenerateMockValue<T>;
    case 'array': {
      const o = options as MockableArrayOptions;
      return generateRandomArray(
        o.minLength || 5,
        o.maxLength || 5,
        o.each,
      ) as GenerateMockValue<T>;
    }
    case 'object': {
      const o = options as MockableObjectOptions;
      const mockObject = {} as Record<string, unknown>;
      for (const key in o.values) {
        mockObject[key] = generateMockValue(o.values[key]);
      }

      return mockObject as GenerateMockValue<T>;
    }
    default:
      return generateRandomCustomType(
        options.type as MockableCustomTypes,
      ) as GenerateMockValue<T>;
  }
};
