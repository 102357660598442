import { Link } from 'react-router-dom';

import { Button, Center, Stack, Title } from '@mantine/core';

export default function Forbidden() {
  return (
    <Center>
      <Stack align="center">
        <Title>Non puoi accedere a questa pagina</Title>
        <Button to="/" component={Link}>
          Torna alla pagina iniziale
        </Button>
      </Stack>
    </Center>
  );
}
