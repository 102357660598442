import { useLayoutEffect, useState } from 'react';
import { useOutletContext, useParams, useSearchParams } from 'react-router-dom';

import { ORDER_STATUS_COLORS, ORDER_STATUS_STRINGS } from '@domain/orderStatus';
import {
  PRODUCT_STATUS_COLORS,
  PRODUCT_STATUS_STRINGS,
} from '@domain/productStatus';
import { SortOrder } from '@domain/types';
import {
  Alert,
  Anchor,
  Badge,
  Button,
  Divider,
  Grid,
  Group,
  Image,
  Modal,
  Skeleton,
  Space,
  Stepper,
  Text,
  Title,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
  IconCircleCheck,
  IconExternalLink,
  IconHourglass,
  IconPencil,
} from '@tabler/icons-react';
import { dateToDateString } from '@utils/date';
import { priceToDecimal } from '@utils/price';

import { OrderProduct } from '@interfaces/orders.interface';

import {
  generateGetOrdersProductsMock,
  generateGetSingleOrderMock,
} from '@api/mocks/orders.mock';
import {
  GetOrdersRequestParams,
  GetOrdersSortBy,
  useCancelProductMutation,
  useConfirmOrderMutation,
  useGetOrderProductsCountQuery,
  useGetOrderProductsQuery,
  useGetSingleOrderQuery,
} from '@api/orders.api';

import Forbidden from '@routes/Forbidden';

import Card from '@components/card/Card';
import EditOrderShippingAddressForm from '@components/EditOrderShippingAddressForm';
import GraphicsDraftsReview from '@components/graphicsDraftReview/GraphicsDraftsReview';
import { LayoutContextType } from '@components/layout/Layout';
import OrderPaymentSection from '@components/orderPaymentSection/OrderPaymentSection';
import PaginationRow from '@components/PaginationRow';
import SearchBar from '@components/SearchBar';
import SortableTable from '@components/sortableTable/SortableTable';

import NotFound from '../NotFound';

const statusIndexMap = {
  inserted: 0,
  waitingConfirmation: 1,
  waitingPayment: 2,
  confirmed: 3,
  production: 4,
  closed: 5,
};

export default function OrderDetail() {
  // ==========================================================================
  // General
  // ==========================================================================
  const { id } = useParams();

  const TRANSITION_DURATION = 200;

  const [searchParams] = useSearchParams();

  // ==========================================================================
  // State
  // ==========================================================================
  const [paymentModalOpened, setPaymentModalOpened] = useState(false);

  const [graphicsModalOpened, setGraphicsModalOpened] = useState(false);
  const [modalGraphicsInfo, setModalGraphicsInfo] = useState<{
    productName: string;
    draftProductsRelationId: string;
  } | null>(null);

  const [filters, setFilters] = useState<GetOrdersRequestParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 5),
    searchQuery: searchParams.get('search') || undefined,
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });

  // ==========================================================================
  // Api
  // ==========================================================================
  const {
    data: order = generateGetSingleOrderMock(),
    isLoading,
    error,
    //  refetch: refetchOrder,
  } = useGetSingleOrderQuery(id!);

  const [confirmOrder, { isLoading: isLoadingConfirmOrder }] =
    useConfirmOrderMutation();

  const [cancelProduct, { isLoading: isCancelProductLoading }] =
    useCancelProductMutation();

  // Get initial products count
  const {
    data: productsCount = { count: 0 },
    isLoading: isLoadingProductsCount,
  } = useGetOrderProductsCountQuery({ id: id!, searchQuery });

  const {
    data: productsData = generateGetOrdersProductsMock(),
    isLoading: isProductsLoading,
    // refetch: refetchProducts,
  } = useGetOrderProductsQuery({
    id: id!,
    params: { ...filters, searchQuery },
  });

  // Map data
  const products = productsData.map((product) => {
    const data = [
      <Image
        src={product.imageUrl}
        alt={product.name}
        h="2rem"
        w="2rem"
        fit="contain"
      />,
      product.sku || '-',
      product.name,
      product.color || '-',
      product.size || '-',
      product.quantity,
      <Group gap={3} wrap="nowrap">
        <span>€</span>
        {product.salePercent && (
          <span>
            <span style={{ textDecoration: 'line-through' }}>
              {priceToDecimal(product.totalPrice)}
            </span>
          </span>
        )}
        <span>
          {priceToDecimal(
            product.totalPrice * (1 - (product.salePercent || 0) / 1000),
          )}
        </span>
      </Group>,
      product.salePercent ? `${product.salePercent / 10}%` : '-',
      product.shippingDate
        ? new Date(product.shippingDate).toLocaleDateString('IT-it')
        : '-',
      <>
        <Badge color={PRODUCT_STATUS_COLORS[product.status]}>
          {PRODUCT_STATUS_STRINGS[product.status]}
        </Badge>
        {product.status === 'waitingGraphicsConfirmation' && (
          <Button
            style={{ display: 'block' }}
            mt="xs"
            onClick={() => handleGraphicVerificationRequestClick(product)}
          >
            Verifica grafica
          </Button>
        )}
        {!order.onlinePayed &&
          (product.status === 'inserted' ||
            product.status === 'checkingAvailability' ||
            product.status === 'waitingConfirmation') && (
            <Button
              style={{ display: 'block' }}
              mt="xs"
              variant="default"
              onClick={() => handleProductCancelRequestClick(product)}
              loading={isCancelProductLoading}
            >
              Rimuovi prodotto
            </Button>
          )}
      </>,
    ];

    return {
      key: product.id,
      data,
    };
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleGraphicVerificationRequestClick = (product: OrderProduct) => {
    setGraphicsModalOpened(true);

    setModalGraphicsInfo({
      productName: product.name,
      draftProductsRelationId: product.draftProductsRelationId!,
    });
  };

  const handleProductCancelRequestClick = async (product: OrderProduct) => {
    try {
      await cancelProduct(product.id).unwrap();

      showNotification({
        title: 'Prodotto rimosso',
        message: 'Il prodotto è stato annullato con successo.',
      });
    } catch (e) {
      console.error(e);

      showNotification({
        title: "Errore nell'annullamento del prodotto!",
        message: "Impossibile procedere con l'annullamento.",
        color: 'red',
      });
    }
  };

  // const handlePaypalCreateOrder = async () => {};

  // const handlePaypalApprove = async (data: OnApproveData) => {};

  const handleConfirmOrderClick = async () => {
    try {
      await confirmOrder({ id: order!.id }).unwrap();
    } catch (e) {
      console.error(e);

      showNotification({
        title: "Errore nella conferma dell'ordine!",
        message: 'Impossibile procedere con la conferma.',
        color: 'red',
      });
    }
  };

  const handleEditShippingInformationsClick = () => {
    openModal({
      title: 'Modifica indirizzo di consegna',
      children: <EditOrderShippingAddressForm order={order!} />,
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================

  const totalPriceNoDiscount = order?.total || 0;
  const discount =
    order?.paymentType === 'bankTransferOnConfirm' ||
    order.paymentType === 'other'
      ? order.subtotal * 0.03
      : 0;
  const netTotal = order.subtotal + order.shippingCost - discount;
  const taxes = netTotal * 0.22;
  const totalPrice = order.netTotal + taxes;

  const totalPages = Math.ceil(productsCount.count / filters.pageLength!);

  const stepperSteps = Object.keys(ORDER_STATUS_STRINGS)
    .filter(
      (status) =>
        status !== 'processingPayment' &&
        (!order.onlinePayed || status !== 'waitingPayment'),
    )
    .map((status) => (
      <Stepper.Step
        key={status}
        label={
          ORDER_STATUS_STRINGS[status as keyof typeof ORDER_STATUS_STRINGS]
        }
        color={ORDER_STATUS_COLORS[status as keyof typeof ORDER_STATUS_STRINGS]}
      />
    ));

  const { setLayoutProps } = useOutletContext<LayoutContextType>();

  useLayoutEffect(() => {
    setLayoutProps({
      title: `Dettagli ordine #${id}`,
      backLink: { title: 'Lista ordini', to: '/ordini' },
    });
  }, [setLayoutProps, id]);

  return !isLoading &&
    (!order || (error && (error as FetchBaseQueryError).status === 404)) ? (
    <NotFound />
  ) : !isLoading &&
    (!order || (error && (error as FetchBaseQueryError).status === 403)) ? (
    <Forbidden />
  ) : (
    <>
      <Modal
        fullScreen
        opened={graphicsModalOpened}
        onClose={() => setGraphicsModalOpened(false)}
        title="Verifica grafica prodotto"
        transitionProps={{ duration: TRANSITION_DURATION }}
        styles={{
          body: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <GraphicsDraftsReview
          productName={modalGraphicsInfo?.productName}
          draftProductsRelationId={modalGraphicsInfo?.draftProductsRelationId}
          closeModal={() => setGraphicsModalOpened(false)}
          transitionDuration={TRANSITION_DURATION}
        />
      </Modal>

      <Modal
        opened={paymentModalOpened}
        onClose={() => setPaymentModalOpened(false)}
        title="Pagamento ordine"
      ></Modal>

      <>
        <Stepper
          visibleFrom="lg"
          my={40}
          active={
            statusIndexMap[
              order?.status
                ? order.status !== 'processingPayment'
                  ? order.status
                  : 'waitingPayment'
                : 'inserted'
            ]
          }
          size="sm"
          styles={{
            step: {
              flexDirection: 'column',
            },
            stepBody: {
              marginLeft: '0',
              marginTop: '7px',
            },
          }}
        >
          {stepperSteps}
        </Stepper>

        {order.status === 'processingPayment' && (
          <Alert title="Pagamento in corso" mb="lg" icon={<IconHourglass />}>
            Pagamento in corso. Ricaricando la pagina fra qualche secondo potrai
            vedere l'ordine come confermato.
          </Alert>
        )}

        {searchParams.get('payment_intent') &&
          searchParams.get('redirect_status') === 'succeeded' && (
            <Alert
              mb="lg"
              color="green"
              title="Pagamento confermato"
              icon={<IconCircleCheck />}
            >
              <Text>Pagamento avvenuto con successo e ordine confermato.</Text>
            </Alert>
          )}

        {order.status === 'waitingPayment' && (
          <>
            <OrderPaymentSection order={order} />
            <Space h="xl" />
          </>
        )}

        <Grid>
          {/* GRID ROW */}
          <Grid.Col span={{ lg: 6, xl: 4 }}>
            <Card title="Informazioni ordine">
              <Group justify="space-between" hiddenFrom="lg">
                <Text fw="bold">Stato ordine</Text>
                <Skeleton visible={isLoading}>
                  <Badge color={ORDER_STATUS_COLORS[order.status]}>
                    {ORDER_STATUS_STRINGS[order.status]}
                  </Badge>
                </Skeleton>
              </Group>

              <Group justify="space-between">
                <Text fw="bold">Numero ordine</Text>
                <Skeleton visible={isLoading}>{order.id}</Skeleton>
              </Group>
              <Group justify="space-between">
                <Text fw="bold">Riferimento cliente</Text>
                <Skeleton visible={isLoading}>
                  {order.customerRef || '-'}
                </Skeleton>
              </Group>
              <Group justify="space-between">
                <Text fw="bold">Data</Text>
                <Skeleton visible={isLoading}>
                  {dateToDateString(new Date(order.date))}
                </Skeleton>
              </Group>
              <Text fw="bold">Informazioni fatturazione</Text>
              <Skeleton visible={isLoading}>
                {order.invoicingName}, {order.invoicingVatTaxNumber}
              </Skeleton>
              {order.invoicingPec ||
                (order.invoicingSdi && (
                  <Skeleton visible={isLoading}>
                    {order.invoicingPec}, {order.invoicingSdi}
                  </Skeleton>
                ))}
              <Skeleton visible={isLoading}>
                {order.invoicingAddress} - {order.invoicingZip},{' '}
                {order.invoicingCity} ({order.invoicingProvince})
              </Skeleton>

              {order.note && (
                <>
                  <Text fw="bold">Note ordine</Text>
                  <Skeleton visible={isLoading}>{order.note}</Skeleton>
                </>
              )}

              {order?.status === 'waitingConfirmation' && (
                <Button
                  leftSection={<IconCircleCheck />}
                  mt="lg"
                  onClick={handleConfirmOrderClick}
                  loading={isLoadingConfirmOrder}
                >
                  Conferma ordine
                </Button>
              )}
            </Card>
          </Grid.Col>

          {/* GRID ROW */}
          <Grid.Col span={{ lg: 6, xl: 4 }}>
            <Card
              title={`Indirizzo di ${order.shippingMethod === 'courier' ? 'consegna' : 'ritiro'}`}
              action={
                order.shippingMethod === 'courier' &&
                order.status === 'inserted'
                  ? {
                      title: 'Modifica',
                      icon: <IconPencil />,
                      onClick: handleEditShippingInformationsClick,
                    }
                  : undefined
              }
            >
              {order.shippingMethod === 'courier' ? (
                <>
                  <Skeleton visible={isLoading}>{order.shippingName}</Skeleton>
                  <Skeleton visible={isLoading}>
                    {order.shippingAddress}
                  </Skeleton>
                  <Skeleton visible={isLoading}>
                    {order.shippingZip} - {order.shippingCity} (
                    {order.shippingProvince}), {order.shippingNation}
                  </Skeleton>
                </>
              ) : order.shippingMethod === 'handPickupShopBassano' ? (
                <>
                  <Skeleton visible={isLoading}>Via Bellavitis, 35</Skeleton>
                  <Skeleton visible={isLoading}>
                    36061 Bassano del Grappa (VI)
                  </Skeleton>
                </>
              ) : order.shippingMethod === 'handPickupShopSpes' ? (
                <>
                  <Skeleton visible={isLoading}>Via Palazzetto, 14</Skeleton>
                  <Skeleton visible={isLoading}>
                    36026 Pojana Maggiore VI
                  </Skeleton>
                </>
              ) : (
                <>
                  <Skeleton visible={isLoading}>
                    Via dell'Artigianato, 41/A
                  </Skeleton>
                  <Skeleton visible={isLoading}>
                    36030 - Villaverla (VI), Italia
                  </Skeleton>
                </>
              )}
            </Card>
          </Grid.Col>

          {/* GRID ROW */}
          <Grid.Col span={{ lg: 6, xl: 4 }}>
            <Card title="Spedizione e pagamento">
              <Group justify="space-between">
                <Text fw="bold">Tipologia spedizione</Text>
                <Skeleton visible={isLoading}>
                  {order?.shippingMethod === 'courier'
                    ? 'Corriere'
                    : 'Ritiro in sede'}
                </Skeleton>
              </Group>

              {order?.shippingMethod === 'courier' && (
                <>
                  <Group justify="space-between">
                    <Text fw="bold">Data spedizione prevista</Text>
                    <Skeleton visible={isLoading}>
                      {order?.shippingDate
                        ? dateToDateString(new Date(order!.shippingDate))
                        : 'Non disponibile'}
                    </Skeleton>
                  </Group>
                  <Group justify="space-between">
                    <Text fw="bold">Tracciamento spedizione</Text>
                    <Skeleton visible={isLoading}>
                      {order?.trackingUrl && order.trackingUrl.length > 0
                        ? order.trackingUrl.map((url, i) => (
                            <Anchor
                              key={url}
                              href={url}
                              style={{
                                display:
                                  order!.trackingUrl!.length > 1
                                    ? 'block'
                                    : 'initial',
                              }}
                            >
                              <Group gap={5} style={{ display: 'inline-flex' }}>
                                Vai al sito{' '}
                                {order!.trackingUrl!.length > 1 ? i : ''}
                                <IconExternalLink size={20} />
                              </Group>
                            </Anchor>
                          ))
                        : 'Non disponibile'}
                    </Skeleton>
                  </Group>
                </>
              )}
              <Divider my="md" />
              <Group justify="space-between">
                <Text fw="bold">Tipologia pagamento</Text>
                <Skeleton visible={isLoading}>
                  {order?.paymentType === 'onlineOnConfirm' ? (
                    'Pagamento online'
                  ) : order?.paymentType === 'onlineStripe' ? (
                    'Stripe'
                  ) : order?.paymentType === 'onlinePaypal' ? (
                    'Paypal'
                  ) : (
                    <Group style={{ display: 'inline-flex' }} gap="xs">
                      Bonifico bancario
                    </Group>
                  )}
                </Skeleton>
              </Group>

              <Group justify="space-between" mt="xs">
                <Text fw="bold">Subtotale</Text>
                <Skeleton visible={isLoading} ta="right">
                  € {priceToDecimal(order.subtotal)}
                </Skeleton>
              </Group>
              {order.shippingMethod === 'courier' && (
                <Group justify="space-between">
                  <Text fw="bold">Costo spedizione</Text>
                  <Skeleton visible={isLoading} ta="right">
                    € {priceToDecimal(order.shippingCost)}
                  </Skeleton>
                </Group>
              )}
              {order.couponDiscount !== 0 && (
                <Group justify="space-between">
                  <Text fw="bold">Sconto</Text>
                  <Skeleton visible={isLoading} ta="right">
                    - € {priceToDecimal(order.couponDiscount)}
                  </Skeleton>
                </Group>
              )}
              <Group justify="space-between">
                <Text fw="bold">Totale netto</Text>
                <Skeleton visible={isLoading} ta="right">
                  € {priceToDecimal(order.netTotal)}
                </Skeleton>
              </Group>
              <Group justify="space-between">
                <Text fw="bold">Tasse</Text>
                <Skeleton visible={isLoading} ta="right">
                  € {priceToDecimal(order.taxes)}
                </Skeleton>
              </Group>
              <Group justify="space-between" mt="xs">
                <Text fw="bold">Totale (Iva In.)</Text>
                <Skeleton visible={isLoading} ta="right">
                  {order?.status === 'inserted' ||
                  order?.status === 'waitingConfirmation' ||
                  order?.status === 'waitingPayment' ? (
                    <>
                      € {priceToDecimal(totalPriceNoDiscount)}
                      <br />
                      {(order.paymentType === 'bankTransferOnConfirm' ||
                        order.paymentType === 'other') && (
                        <>(€ {priceToDecimal(totalPrice)} pagando online)</>
                      )}
                    </>
                  ) : order?.total !== totalPriceNoDiscount ? (
                    <>
                      <span style={{ textDecoration: 'line-through' }}>
                        € {priceToDecimal(totalPriceNoDiscount)}
                      </span>{' '}
                      {order.paymentType !== 'onlineOnConfirm' && (
                        <>€ {priceToDecimal(order.total)}</>
                      )}
                    </>
                  ) : (
                    <>€ {priceToDecimal(totalPriceNoDiscount)}</>
                  )}
                </Skeleton>
              </Group>

              {order.status === 'waitingPayment' &&
                (order.paymentType === 'bankTransferOnConfirm' ? (
                  <Text ta="justify" mt="md">
                    Puoi scegliere di pagare subito il tuo ordine tramite carta
                    di credito o paypal ed ottenere uno sconto del 3%, oppure
                    proseguire con il pagamento tramite bonifico bancario.
                  </Text>
                ) : (
                  order.paymentType === 'other' && (
                    <Text ta="justify" mt="md">
                      Puoi scegliere di pagare subito il tuo ordine tramite
                      carta di credito o paypal ed ottenere uno sconto del 3%,
                      oppure proseguire con il pagamento tramite bonifico
                      bancario.
                    </Text>
                  )
                ))}
            </Card>
          </Grid.Col>
        </Grid>

        <Title order={3} mt="xl">
          Prodotti
        </Title>
        <Group mt="lg" mb="md">
          <SearchBar
            placeholder="Ricerca per codice o nome prodotto"
            value={filters.searchQuery}
            onChange={(newValue) =>
              setFilters({ ...filters, searchQuery: newValue })
            }
          />
        </Group>
        <SortableTable
          data={products}
          headings={{
            imageUrl: '',
            productSku: 'Codice',
            name: 'Nome',
            color: 'Colore',
            size: 'Taglia',
            quantity: 'Qta.',
            totalPrice: 'Prezzo tot.',
            salePercent: 'Sconto',
            shippingDate: 'Data sped. prev.',
            status: 'Stato',
          }}
          sortableKeys={['sku', 'name']}
          onSortingChange={(key: string, order: SortOrder) =>
            setFilters({
              ...filters,
              sortBy: key as GetOrdersSortBy,
              sortOrder: order,
            })
          }
          emptyText="Nessun prodotto trovato"
          loading={isProductsLoading || isLoadingProductsCount}
        />

        <PaginationRow
          page={filters.page!}
          pageLength={filters.pageLength!}
          totalPages={totalPages}
          onPageChange={(newPage) => setFilters({ ...filters, page: newPage })}
          onPageLengthChange={(newPageLength) =>
            setFilters({ ...filters, pageLength: newPageLength, page: 1 })
          }
        />
      </>
    </>
  );
}
