import { useLayoutEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import { ActionIcon, Button, Group, Space, Text, Tooltip } from '@mantine/core';
import { openConfirmModal, openModal } from '@mantine/modals';
import {
  IconChevronDown,
  IconChevronUp,
  IconPencil,
  IconPlus,
  IconTrash,
} from '@tabler/icons-react';
import { priceToDecimal } from '@utils/price';

import {
  ShippingCost,
  useDecrementShippingCostPriorityMutation,
  useDeleteShippingCostMutation,
  useGetShippingCostsQuery,
  useIncrementShippingCostPriorityMutation,
} from '@api/shippingCosts.api';

import { LayoutContextType } from '@components/layout/Layout';
import SortableTable from '@components/sortableTable/SortableTable';
import UpsertShippingCostForm from '@components/UpsertShippingCostForm';

export default function ShippingCosts() {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data = [], isLoading } = useGetShippingCostsQuery();

  const [deleteShippingCost, { isLoading: isLoadingDelete }] =
    useDeleteShippingCostMutation();

  const [incrementShippingCostPriority, { isLoading: isLoadingIncrement }] =
    useIncrementShippingCostPriorityMutation();
  const [decrementShippingCostPriority, { isLoading: isLoadingDecrement }] =
    useDecrementShippingCostPriorityMutation();

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleCreateShippingCostClick = () => {
    openModal({
      title: 'Crea costo di spedizione',
      children: <UpsertShippingCostForm />,
    });
  };

  const handleUpdateShippingCostClick = (shippingCost: ShippingCost) => {
    openModal({
      title: 'Modifica costo di spedizione',
      children: <UpsertShippingCostForm shippingCost={shippingCost} />,
    });
  };

  const handleDeleteShippingCostClick = (id: number) => {
    openConfirmModal({
      title: 'Elimina costo di spedizione',
      children:
        'Eliminando il costo di spedizione questo non sarà più disponibile al checkout. Proseguire?',
      labels: {
        cancel: 'Annulla',
        confirm: 'Elimina costo di spedizione',
      },
      confirmProps: { color: 'red' },
      onConfirm: () => deleteShippingCost(id),
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const { setLayoutProps } = useOutletContext<LayoutContextType>();

  useLayoutEffect(() => {
    setLayoutProps({
      title: 'Costi di spedizione',
    });
  }, [setLayoutProps]);

  // Map data
  const shippingCosts = data.map((shippingCost, i) => {
    const mappedData = [
      <Group gap={0}>
        {shippingCost.priority}
        {data.length > 1 && (
          <>
            <Space w="lg" />
            {i !== 0 && (
              <Tooltip label="Sposta sopra">
                <ActionIcon
                  variant="subtle"
                  onClick={() => incrementShippingCostPriority(shippingCost.id)}
                  loading={isLoadingIncrement || isLoadingDecrement}
                >
                  <IconChevronUp />
                </ActionIcon>
              </Tooltip>
            )}
            {i !== data.length - 1 && (
              <Tooltip label="Sposta sotto">
                <ActionIcon
                  variant="subtle"
                  onClick={() => decrementShippingCostPriority(shippingCost.id)}
                  loading={isLoadingIncrement || isLoadingDecrement}
                >
                  <IconChevronDown />
                </ActionIcon>
              </Tooltip>
            )}
          </>
        )}
      </Group>,
      `€ ${priceToDecimal(shippingCost.value)}`,
      shippingCost.minOrderValue
        ? `€ ${priceToDecimal(shippingCost.minOrderValue)}`
        : '-',
      shippingCost.provinces && shippingCost.provinces.length > 0
        ? shippingCost.provinces.map((p) => p.provinceCode).join(', ')
        : 'Tutte le province',
      <Group>
        <Tooltip label="Modifica">
          <ActionIcon
            onClick={() => handleUpdateShippingCostClick(shippingCost)}
          >
            <IconPencil />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Elimina">
          <ActionIcon
            color="red"
            onClick={() => handleDeleteShippingCostClick(shippingCost.id)}
            loading={isLoadingDelete}
          >
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Group>,
    ];

    return {
      key: shippingCost.id,
      data: mappedData,
    };
  });

  return (
    <>
      <Text maw={800}>
        I costi di spedizione sotto indicati verranno applicati in fase di
        checkout. Il costo valido (che rispetta le condizioni di valore minimo
        ordine e province) con priorità più bassa verrà applicato.
      </Text>
      <Button
        my="lg"
        onClick={handleCreateShippingCostClick}
        leftSection={<IconPlus />}
      >
        Aggiungi costo di spedizione
      </Button>
      <SortableTable
        loading={isLoading}
        headings={{
          priority: 'Priorità',
          value: 'Costo',
          minOrderValue: 'Valore min. ordine',
          provinces: 'Province',
          actions: '',
        }}
        data={shippingCosts}
        emptyText="Nessun costo di spedizione trovato"
        lastColumnRight
      />
    </>
  );
}
