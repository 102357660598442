import {
  Loader,
  MantineThemeOverride,
  Paper,
  Skeleton,
  Switch,
} from '@mantine/core';

const theme: MantineThemeOverride = {
  fontFamily: 'Montserrat, sans-serif',
  headings: {
    fontFamily: 'Montserrat, sans-serif',
    sizes: {
      h1: {
        fontSize: '1.5rem',
      },
      h2: {
        fontSize: '1.2rem',
      },
    },
  },

  black: '#55607c',

  colors: {
    brand: [
      '#fff0e7',
      '#fcdfd3',
      '#f3bea8',
      '#eb9a78',
      '#e57c50',
      '#e16836',
      '#e05e28',
      '#c74e1c',
      '#b24416',
      '#9c380e',
    ],
  },
  primaryColor: 'brand',

  defaultRadius: 0,

  components: {
    Loader: Loader.extend({
      defaultProps: {
        type: 'dots',
      },
    }),
    Paper: Paper.extend({
      defaultProps: {
        withBorder: true,
        // shadow: 'md',
      },
    }),
    Switch: Switch.extend({
      styles: {
        track: {
          cursor: 'pointer',
        },
      },
    }),
    Skeleton: Skeleton.extend({
      styles: {
        root: {
          width: 'max-content',
        },
      },
    }),
  },
};

export default theme;
