import { Button, Group, MultiSelect, NumberInput, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/forms';
import { priceToDecimal } from '@utils/price';

import { useGetProvincesQuery } from '@api/locations.api';
import {
  ShippingCost,
  useCreateShippingCostMutation,
  useUpdateShippingCostMutation,
} from '@api/shippingCosts.api';

interface UpsertShippingCostFormProps {
  shippingCost?: ShippingCost;
  newPriority?: number;
}

export default function UpsertShippingCostForm({
  shippingCost,
}: UpsertShippingCostFormProps) {
  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    value: shippingCost?.value ? priceToDecimal(shippingCost.value) : 0,
    minOrderValue: shippingCost?.minOrderValue
      ? priceToDecimal(shippingCost.minOrderValue)
      : undefined,
    provincesCodes: shippingCost?.provinces?.map((p) => p.provinceCode) || [],
  };

  const form = useForm({
    initialValues,
  });

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: provinces = [], isLoading: isLoadingProvinces } =
    useGetProvincesQuery();

  const [createShippingCost, { isLoading: isLoadingCreateShippingCost }] =
    useCreateShippingCostMutation();
  const [updateShippingCost, { isLoading: isLoadingUpdateShippingCost }] =
    useUpdateShippingCostMutation();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = async (values: typeof initialValues) => {
    try {
      if (shippingCost) {
        await updateShippingCost({
          id: shippingCost.id,
          body: {
            ...values,
            value: Math.round(values.value * 1000),
            minOrderValue: values.minOrderValue
              ? Math.round(values.minOrderValue * 1000)
              : null,
          },
        }).unwrap();
      } else {
        await createShippingCost({
          ...values,
          value: Math.round(values.value * 1000),
          minOrderValue: values.minOrderValue
            ? Math.round(values.minOrderValue * 1000)
            : null,
        }).unwrap();
      }

      closeAllModals();

      showNotification({
        title: `Costo di spedizione ${shippingCost ? 'modificato' : 'aggiunto'}`,
        message: `Costo di spedizione ${shippingCost ? 'modificato' : 'aggiunto'} con successo`,
      });
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <NumberInput
        label="Costo"
        required
        prefix="€ "
        min={0}
        clampBehavior="strict"
        decimalScale={2}
        {...form.getInputProps('value')}
      />
      <NumberInput
        mt="xs"
        label="Valore minimo ordine"
        prefix="€ "
        min={0}
        clampBehavior="strict"
        decimalScale={2}
        {...form.getInputProps('minOrderValue')}
      />
      <MultiSelect
        mt="xs"
        label="Province"
        placeholder="Tutte le province"
        searchable
        nothingFoundMessage="Nessun risultato"
        disabled={isLoadingProvinces}
        data={provinces.map((province) => ({
          value: province.sigla,
          label: province.sigla,
        }))}
        {...form.getInputProps('provincesCodes')}
      />

      {form.errors.general && (
        <Text c="red" size="sm" mt="xl">
          {form.errors.general}
        </Text>
      )}
      <Group mt="xl">
        <Button
          variant="default"
          onClick={() => closeAllModals()}
          loading={isLoadingCreateShippingCost || isLoadingUpdateShippingCost}
        >
          Annulla
        </Button>
        <Button
          type="submit"
          loading={isLoadingCreateShippingCost || isLoadingUpdateShippingCost}
        >
          Conferma
        </Button>
      </Group>
    </form>
  );
}
